@import "./mediaquery";
@import "./variables.scss";

.requests {
  height: 100vh;
  width: 100%;
  padding: 1em;
  position: relative;

  .bt {
    border: none;
    background-color: $primary;
    color: white;
    cursor: pointer;
    margin: 10px 0 1em auto;
    display: block;
    padding: 8px;
    border-radius: 5px;
  }

  .div3equal {
    gap: 2em !important;
  }

  .litem {
    h3 {
      font-size: large;
      font-weight: 500;
      margin: 1em 0 1em 0;
      padding-left: 10px;
    }
    .content {
      .accordion-body {
        margin: 5px 0 5px 0;
        border-radius: 4px;
        box-shadow: 1px 1px 5px #60606030;
        border: 1px solid #60606030;
        display: grid;
        grid-template-columns: minmax(auto, 0.5fr) 2fr;
        place-content: center;
        gap: 0px;
        overflow: hidden;
        background-color: white;
        cursor: pointer;

        .status {
          width: fit-content;
          margin: 5px 0 0 auto;
          display: grid;
          grid-template-columns: auto auto;
          gap: 5px;

          p {
            display: block;
            margin: auto;
            font-size: small;
          }

          div {
            height: 16px;
            width: 16px;
            background-color: orange;
            border-radius: 16px;
            margin: auto;
          }
        }

        .date {
          background-color: $primary;
          color: white;
          text-align: center;
          padding: 5px;

          p {
            padding: 5px;
            font-size: small;
          }
        }

        .bdy {
          padding: 10px;

          label {
            color: gray;
            margin: 8px 0 8px 0;
            padding: 4px;
            font-size: small;
          }

          h5 {
            color: $primary;
            padding: 4px;
            font-size: small;
            font-weight: 500;
          }

          p {
            padding: 4px;
            font-size: small;
          }

          h4 {
            padding: 4px;
            font-size: small;
            font-weight: 500;
          }
        }
      }

      .accordion-body:hover {
        box-shadow: 2px 2px 5px $primary;
      }
    }
  }

  .list {
    background-color: white;
    padding: 1em;
    border-radius: 5px;
    position: relative;
    margin-bottom: 1em;

    h3 {
      padding: 10px 0 10px 0;
      font-size: medium;
      color: $primary;
    }

    .consent {
      .ic {
        padding-top: 10px;
      }
      p {
        font-size: small;
        margin: 5px 0 0 0;
      }
    }

    .bar {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1px;

      p {
        padding: 5px;
        background-color: $primary;
        cursor: pointer;
        font-weight: 500;
        color: white;
        border-radius: 2px;
        border-bottom: 2px solid $primary;
      }

      .active {
        background-color: white;
        color: $primary !important;
      }
    }

    .new {
      h3 {
        color: $secondary;
        font-size: medium;
        font-weight: 500;
      }

      hr {
        color: #60606010;
      }

      .payment {
        margin-top: 10px;
        margin-bottom: 1.5em;

        .div1auto {
          display: grid;
          grid-template-columns: 1fr auto;
          margin-bottom: 2em;

          .input-container {
            margin-bottom: 0;
          }

          button {
            display: block;
            margin: auto;
            background-color: $primary;
            border: none;
            border-radius: 5px;
            color: $accent;
            cursor: pointer;
            height: 100%;
            padding: 8px 2em;
          }
        }

        .mpesa {
          background-color: rgb(3, 219, 3);
          padding: 10px 1em;
          border-radius: 5px;
          border: 1px solid white;
          color: white;
          font-weight: bolder;
          font-size: large;
          width: fit-content;
          margin-top: 10px;
        }

        p {
          margin: 8px 0;
          color: $primary;
          b {
            font-weight: 520;
            color: black;
          }
        }

        h5 {
          font-size: medium;
          color: $primary;
          margin-bottom: 10px;
        }
      }

      .filelist {
        .file {
          display: grid;
          grid-template-columns: auto auto;
          gap: 1em;
          width: fit-content;
          box-shadow: 1px 1px 2px #60606030;
          border: 1px solid #60606030;
          border-radius: 3px;
          padding: 5px;
          margin-bottom: 3px;
          cursor: pointer;

          i {
            display: block;
            margin: auto;
            text-align: center;
          }
        }
        .file:hover {
          i {
            color: red;
          }
        }
      }
      .search {
        position: relative;

        .search_list {
          position: absolute;
          left: 0;
          right: 0;
          top: 74px;
          padding: 10px;
          border: 1px solid #60606030;
          border-radius: 5px;
          box-shadow: 1px 1px 5px #60606030;

          p {
            font-size: small;
            padding: 5px;
            cursor: pointer;
            margin-bottom: 3px;
          }
          p:hover {
            color: $secondary;
          }
        }
      }

      .item {
        box-shadow: 1px 1px 5px #60606030;
        border-radius: 5px;
        width: 100%;
        position: relative;
        padding: 10px;
        margin-top: 34px;

        h3,
        p {
          font-size: small;
        }

        .fa-times {
          position: absolute;
          right: 10px;
          top: 10px;
        }
      }

      button {
        display: block;
        margin: 1em auto 1em auto;
        padding: 12px;
        width: 80%;
        background-color: $primary;
        color: white;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 3px solid rgba($primary, 0.5);
        border-top: 2px solid #60606070;
        cursor: pointer;
      }
      button:hover {
        background-color: rgba($color: $primary, $alpha: 0.8);
      }
      .input {
        margin: 10px 0 10px 0;

        label {
          display: block;
          margin: 0px 0 10px 0;
          font-weight: 400;
          font-size: small;
        }

        textarea {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          background-color: $bg;
          border-top: none;
          font-size: small;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 3px solid rgba($primary, 0.5);
        }
        textarea:focus {
          border-bottom: 2px solid $primary;
        }

        input {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          border-radius: 5px;
          background-color: $bg;
          border: 1px solid $primary;
          outline: none;
          border-bottom: 3px solid rgba($primary, 0.5);
        }
        input:focus {
          border-bottom: 2px solid $primary;
        }
      }

      .select {
        margin-top: 1em;
      }
      // .select {
      //   margin: 10px 0 10px 0;

      //   label,
      //   h4 {
      //     display: block;
      //     margin: 0px 0 10px 0;
      //     font-weight: 400;
      //   }

      //   select {
      //     margin: 0px 0 10px 0;
      //     padding: 10px;
      //     width: 100%;
      //     background-color: $bg1;
      //     border-top: none;
      //     border-right: none;
      //     border-left: none;
      //     outline: none;
      //     border-bottom: 3px solid rgba($primary, 0.5);
      //   }
      //   input:focus {
      //     border-bottom: 2px solid $primary;
      //   }
      // }

      .checks {
        h4 {
          display: block;
          margin: 0px 0 10px 0;
          font-weight: 400;
        }

        .s_list {
          box-shadow: 1px 1px 3px #60606010;
          border: 1px solid #60606010;
          border-radius: 2px;
          margin: 6px 0 6px 0;
          display: grid;
          grid-template-columns: 1fr auto;
          gap: 10px;

          .fa-times {
            display: block;
            padding: 6px;
            margin: auto;
            cursor: pointer;
            color: $primary;
          }
          .fa-times:hover {
            color: $accent;
          }

          p {
            font-size: small;
            padding: 10px;
          }
        }

        .dropdown {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 99;
          display: flex;
          align-items: center;
          align-content: center;
          background-color: rgba($color: #000000, $alpha: 0.05);
          padding: 10px;

          .content {
            width: fit-content;
            margin: auto;
            padding: 1em;
            background-color: white;
            box-shadow: 1px 1px 3px #60606010;
            border: 1px solid #60606010;
            border-radius: 5px;
            position: relative;

            .fa-times {
              color: $accent;
              position: absolute;
              top: 1em;
              right: 1em;
              cursor: pointer;
            }
          }

          .dlist {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;

            .t_list {
              display: grid;
              grid-template-columns: 1fr auto;
              gap: 10px;
              cursor: pointer;
              box-shadow: 1px 1px 3px #60606010;

              .fa-plus {
                display: block;
                padding: 6px;
                margin: auto;

                color: $primary;
              }

              p {
                font-size: small;
                padding: 4px;
              }
            }
            .t_list:hover {
              background-color: $bg;
            }
          }
        }

        .add {
          margin: 1em auto 10px auto;
          padding: 5px 2em 5px 2em;
          background-color: $primary;
          border-radius: 5px;
          color: white;
          cursor: pointer;
        }
        .add:hover {
          background-color: $accent;
        }
      }

      .div2equal {
        gap: 3em !important;
      }

      h6 {
        width: fit-content;
        padding: 10px 1em 10px 1em;
        cursor: pointer;
        margin: 0 0 0 auto;
        font-size: small;
        font-weight: 500;
        background-color: $bg;
        border-radius: 5px;
      }

      h6:hover {
        background-color: $accent;
      }

      .u_list {
        box-shadow: 1px 1px 3px #60606010;
        border: 1px solid #60606010;
        border-radius: 2px;
        margin: 6px 0 6px 0;
        padding: 10px;
        position: relative;

        .div2equal {
          gap: 1em !important;
        }

        .fa-times {
          position: absolute;
          right: 10px;
          top: 10px;
          color: $accent;
          cursor: pointer;
        }

        h4 {
          font-size: medium;
          font-weight: 500;
          text-align: center;
          color: $primary;
        }

        b {
          font-weight: 500;
        }

        p {
          font-size: small;
          font-weight: 200;
          padding: 3px;
        }
      }
    }
  }
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    align-items: center;
    z-index: 999999;
    animation: zoom 1s ease;

    .pcontainer {
      background-color: white;
      border-radius: 4px;
      margin: 0 auto 0 auto;
      color: #000000;
      max-width: 70vw;
      max-height: 90vh;
      min-width: 50%;
      height: fit-content;
      overflow-y: auto;
      width: fit-content;
      position: relative;

      h5 {
        color: $primary;
        cursor: pointer;
        font-size: small !important;
      }

      .tbar {
        background-color: $primary;
        padding: 1px !important;

        h3 {
          text-align: center;
          font-size: medium;
          color: white;
        }

        .fa {
          position: absolute;
          top: 10px;
          right: 10px;
          color: white;
          height: fit-content;
          margin: auto;
          padding: 5px;
        }
        .fa:hover {
          color: $accent;
        }
      }

      .wcontent {
        padding: 1em;
        position: relative;

        h6 {
          text-align: center;
          color: $accent;
          margin-top: 5px;
          font-weight: 500;
        }

        .u_list {
          box-shadow: 1px 1px 3px #60606010;
          border: 1px solid #60606010;
          border-radius: 2px;
          margin: 6px 0 6px 0;
          padding: 10px;
          position: relative;
          cursor: pointer;

          .updateprp {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            z-index: 999;
            background-color: #60606010;

            .up_wrapper {
              margin: auto;
              background-color: white;
              min-width: 250px;
              width: fit-content;
              padding: 1em;
              box-shadow: 1px 1px 3px #60606010;
              border: 1px solid #60606010;
              border-radius: 5px;

              h6 {
                margin: 5px 0 5px 0;
                min-height: 16px;
                color: $accent;
                text-align: center;
                font-size: small;
                font-weight: 100;
              }

              .usrinput {
                margin: 10px 0 10px 0;

                h4 {
                  display: block;
                  text-align: left;
                  font-weight: 400;
                  font-size: small;
                  color: #000000 !important;
                }

                input {
                  margin-top: 0px !important;
                  padding: 10px;
                  width: 100%;
                  background-color: $bg1;
                  border-top: none;
                  border-right: none;
                  border-left: none;
                  outline: none;
                  border-bottom: 3px solid rgba($primary, 0.5);
                }
                input:focus {
                  border-bottom: 2px solid $primary;
                }
              }
            }
          }

          .p_check {
            box-shadow: 1px 1px 3px #60606010;
            border: 1px solid #60606010;
            border-radius: 2px;
            margin: 6px 0 6px 0;
            padding: 5px;
            position: relative;
            cursor: pointer;
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 10px;

            div {
              height: 100%;
              width: 24px;
              height: 24px;
              margin: auto !important;
              background-color: red;
              border-radius: 24px;
            }

            p {
              padding: 0 !important;
            }
          }

          .p_check:hover {
            background-color: #fff5b8;
          }

          hr {
            color: #60606010;
            margin-top: 5px;
          }

          .div2equal {
            gap: 1em !important;
          }

          .no {
            position: absolute;
            right: 5px;
            top: 0;
            bottom: 0;
            height: 100%;
            left: auto;
            display: flex;
            align-items: center;

            p {
              color: white;
              font-size: small;
              font-weight: 600;
              text-align: center;
              padding: 5px 10px;
              border-radius: 24px;
              background-color: $accent;
            }
          }

          .fa-times {
            position: absolute;
            right: 10px;
            top: 10px;
            color: $accent;
            cursor: pointer;
          }

          h3,
          h4 {
            font-size: medium;
            font-weight: 500;
            text-align: center;
            color: $primary;
          }

          b {
            font-weight: 500;
          }

          p {
            font-size: small;
            font-weight: 200;
            padding: 3px;
          }
        }

        .div3equal {
          gap: 10px !important;
        }

        .usrinput {
          margin: 10px 0 10px 0;

          h4 {
            display: block;
            margin-bottom: 10px !important;
            font-weight: 400;
            font-size: small;
          }

          input {
            margin-top: 10px;
            padding: 10px;
            width: 100%;
            background-color: $bg1;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 3px solid rgba($primary, 0.5);
          }
          input:focus {
            border-bottom: 2px solid $primary;
          }
        }

        .search {
          position: relative;

          .search_list {
            position: absolute;
            left: 0;
            right: 0;
            top: 74px;
            padding: 5px;
            border: 1px solid #60606030;
            border-radius: 5px;
            box-shadow: 1px 1px 5px #60606030;
            z-index: 999;
            background-color: white;

            p {
              font-size: x-small;
              padding: 5px;
              cursor: pointer;
              margin-bottom: 3px;
            }
            p:hover {
              color: $secondary;
            }
          }
        }

        .selected_staff {
          box-shadow: 1px 1px 5px #60606030;
          border-radius: 5px;
          width: 100%;
          position: relative;
          padding: 10px;
          margin-top: 10px;

          h3,
          p {
            font-size: small;
          }

          .fa-times {
            position: absolute;
            right: 10px;
            top: 10px;
          }
        }

        button {
          display: block;
          margin: 10px auto 10px auto;
          padding: 4px;
          width: 80%;
          font-size: small;
          background-color: $primary;
          color: white;
          border-top: none;
          border-right: none;
          border-left: none;
          border-bottom: 3px solid rgba($primary, 0.5);
          border-top: 2px solid #60606070;
          cursor: pointer;
        }
        button:hover {
          background-color: rgba($color: $primary, $alpha: 0.8);
        }

        .div {
          box-shadow: 1px 1px 3px #60606010;
          border: 1px solid #60606010;
          border-radius: 2px;
          padding: 10px;
          min-height: 90%;
          height: 100%;
        }

        .prg {
          display: grid;
          grid-template-rows: repeat(5, 1fr);
          gap: 5px;
          height: auto;

          .item {
            display: grid;
            grid-template-columns: 5px 1fr;
            gap: 10px;
            height: 100%;

            p {
              display: block;
              margin: auto;
              width: 100%;
            }

            div {
              height: 100%;
              width: 100%;
              background-color: orange;
            }
          }
        }

        h5 {
          font-size: medium;
          font-weight: 600;
          margin-bottom: 10px;
          line-height: 24px;
          height: 24px;
        }

        h4,
        p {
          margin: 4px 0 4px 0;
          font-size: small;
        }

        h4 {
          font-weight: 600;
        }

        .link {
          color: $primary;
          text-transform: capitalize;
          cursor: pointer;
        }
        .link:hover {
          color: $secondary;
        }
      }
    }
  }

  @include maxwidth(mobile) {
    height: fit-content !important;

    .list {
      height: fit-content !important;
      margin-bottom: 3em !important;
    }
    .popup {
      position: fixed !important;

      .div3equal {
        .div {
          margin-bottom: 10px !important;
          min-height: auto !important;
        }
      }

      .pcontainer {
        max-width: 85vw !important;
        width: 100% !important;
        height: 100% !important;
        overflow-y: auto;

        .link {
          word-break: break-all !important;
        }
      }
    }

    h2,
    h3,
    p,
    h4,
    h5,
    label,
    h6 {
      font-size: small !important;
    }

    .div3equal,
    .div2equal {
      grid-template-columns: 1fr !important;
    }
  }
  @include maxwidth(tablet) {
    height: fit-content !important;

    .list {
      height: fit-content !important;
      margin-bottom: 3em !important;
    }
    .popup {
      position: fixed !important;

      .div3equal {
        .div {
          margin-bottom: 10px !important;
          min-height: auto !important;
        }
      }

      .pcontainer {
        max-width: 85vw !important;
        width: 100% !important;
        height: 100% !important;
        overflow-y: auto;

        .link {
          word-break: break-all !important;
        }
      }
    }
    h2,
    h3,
    p,
    h4,
    h5,
    label,
    h6 {
      font-size: medium !important;
    }

    .div3equal,
    .div2equal {
      grid-template-columns: 1fr !important;
    }
  }
}
