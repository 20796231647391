@import "./mediaquery";
@import "./variables";

.home {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100% !important;

  .main {
    display: grid;
    grid-template-columns: auto 1fr;
    width: 100%;
    height: 100%;

    .fa-times {
      display: none;
    }

    .right_panel {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-rows: auto 1fr;
      overflow: hidden !important;

      .full {
        width: 100%;
        height: 100%;
        overflow-y: auto !important;
      }
    }
  }

  @include maxwidth(mobile) {
    .main {
      display: block;

      .left_panel {
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        width: 70%;
        height: 100%;
        z-index: 11;
        background-color: gray;

        img {
          height: 100%;
          width: 100% !important;
        }

        .fa-bars {
          display: block !important;
        }

        .fa-times {
          display: block;
          position: absolute;
          left: 1em;
          top: 1em;
          font-size: large;
          color: $accent;
          cursor: pointer;
        }
      }
    }
  }
  @include maxwidth(tablet) {
    .main {
      display: block;

      .left_panel {
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        width: 70%;
        height: 100%;
        z-index: 11;
        background-color: gray;

        img {
          height: 100%;
          width: 100% !important;
        }

        .fa-bars {
          display: block !important;
        }

        .fa-times {
          display: block;
          position: absolute;
          left: 1em;
          top: 1em;
          font-size: large;
          color: $accent;
          cursor: pointer;
        }
      }
    }
  }
}
