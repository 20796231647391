@import "./mediaquery";
@import "./variables";

.myhome {
  height: 100%;
  width: 100%;
  padding: 1em;

  .mhead {
    background-image: url("../assets/images/bg4.jpg");
    background-position: bottom;
    background-repeat: no-repeat;
    height: 35vh;
    position: relative;
    border-radius: 5px 5px 0 0;

    .ctn {
      color: white;
      background-color: rgba($color: #000000, $alpha: 0.52);
      padding: 1em;
      width: 100%;
      height: 35vh;

      h1 {
        font-size: 44px;
      }
      h2 {
        font-size: 36px;
      }
    }

    .floating {
      background-color: white;
      position: absolute;
      top: 75%;
      left: 7.5%;
      right: 7.5%;
      display: grid;
      grid-template-columns: auto 1fr auto 1fr auto 1fr auto;
      z-index: 1;
      box-shadow: 2px 2px 10px #60606030;

      .border {
        width: 5px;
        background-image: linear-gradient(to bottom, $primary, $accent);
        display: grid;
        grid-template-rows: 3fr 1fr;
        height: 100%;

        :first-child {
          height: 100%;
          width: 100%;
          background-color: $primary;
        }
        :last-child {
          height: 100%;
          width: 100%;
          background-color: $accent;
        }
      }

      .fitem {
        padding: 1em;
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 1em;

        h4 {
          font-size: xx-large;
          font-weight: bolder;
          display: grid;
          place-content: center;
          color: $primary;
        }

        div {
          margin: auto;
          width: 100%;

          h5 {
            font-size: medium;
            color: $secondary;
          }

          p {
            margin-top: 5px;
            font-size: small;
            color: gray;
          }
        }
      }
    }
  }

  .topdivider {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1em;
    margin-bottom: 1em;

    .disclaimer {
      background-color: white;
      border-radius: 5px;
      box-shadow: 1px 1px 5px #60606020;
      border: 1px solid #60606020;
      background-image: url("../assets/images/warning.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      display: flex;
      align-items: center;

      .disc {
        background-color: rgba($color: white, $alpha: 0.8);
        padding: 1em 2em;
        height: 100%;
      }

      h3 {
        color: $primary;
        text-align: center;
        font-weight: 600;
        margin: auto;
        height: fit-content;
        font-size: x-large;
        padding: 1em 0 10px 0;
      }

      p {
        width: 100%;
        text-align: center;
        font-weight: 200;
        height: fit-content;
        line-height: 1.5;
        font-size: small;
        margin: 10px 0 10px 0;
      }
    }
    .top {
      background-color: #f7e5e9;
      box-shadow: 1px 1px 5px #60606020;
      border: 1px solid #60606020;
      padding: 1em;
      margin-top: 44px;
      border-radius: 5px;
      position: relative;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2em;

      .left {
        display: grid;
        grid-template-rows: 1fr 3fr;
        padding: 2em 1em 1em 1em;
        position: relative;

        h1 {
          position: absolute;
          top: -64px;
          left: -0.9em;
          font-size: x-large;
          color: #fd6976;
        }

        h3 {
          color: #fd6976;
          text-align: center;
          font-weight: 600;
          margin: auto;
          height: fit-content;
          font-size: x-large;
        }

        p {
          width: 100%;
          text-align: center;
          font-weight: 200;
          font-size: small;
          margin: auto;
          height: fit-content;
          line-height: 1.5;
        }
      }

      .right {
        img {
          left: auto;
          right: auto;
          max-height: 250px;
          height: 100%;
          width: 100%;
          object-fit: contain;
          display: block;
          margin-top: -54px;
        }
      }
    }
  }

  .top {
    h1 {
      font-size: x-large;
      color: $primary;
      padding: 1em;
    }

    h3 {
      color: $primary;
      padding: 1em;
      font-weight: 600;
      margin: auto;
      height: fit-content;
      font-size: large;
    }
  }

  .mob {
    display: none;
  }

  .list {
    background-color: white;
    padding: 1em;
    border-radius: 5px;
    position: relative;
  }

  .requests {
    margin-top: 10%;

    .selector {
      background-color: white;
      margin-bottom: 10px;
      // margin-left: 1em;
      // margin-right: 1em;

      .cont {
        display: grid;
        grid-template-columns: repeat(3, auto);
        gap: 3em;
        width: fit-content;

        .item {
          display: grid;
          grid-template-columns: 10px 1fr;

          div {
            width: 100%;
            height: 100%;
          }
        }

        h3 {
          cursor: pointer;
          font-weight: 560;
          width: fit-content;
          font-size: medium;
          padding: 8px;
        }

        .item:hover {
          h3 {
            color: $primary;
          }

          div {
            background-color: $accent;
          }
        }

        .active {
          display: grid;
          grid-template-columns: 10px 1fr;

          div {
            width: 100%;
            height: 100%;
          }

          h3 {
            color: $primary;
          }

          div {
            background-color: $accent;
          }
        }
      }
    }
  }

  @include maxwidth(mobile) {
    .topdivider {
      display: none !important;
    }

    .requests {
      margin-top: 120%;
    }

    .mhead {
      height: 20vh !important;

      .ctn {
        position: relative !important;
        // height: 20vh !important;
      }
      .floating {
        grid-template-columns: 1fr !important;
      }
    }
    .mob {
      display: block;

      h1 {
        font-size: large !important;
        color: $primary;
        margin-bottom: 10px;
      }

      h2 {
        font-size: medium !important;
        color: $primary;
        margin-bottom: 10px;
      }
    }
  }
  @include maxwidth(tablet) {
    .topdivider {
      display: none !important;
    }

    .requests {
      margin-top: 120%;
    }

    .mhead {
      height: 20vh !important;

      .ctn {
        position: relative !important;
        // height: 20vh !important;
      }
      .floating {
        grid-template-columns: 1fr !important;
      }
    }
    .mob {
      display: block;

      h1 {
        font-size: large !important;
        color: $primary;
        margin-bottom: 10px;
      }

      h2 {
        font-size: medium !important;
        color: $primary;
        margin-bottom: 10px;
      }
    }
  }
}
