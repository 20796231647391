@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Raleway:900&display=swap");
@import "./Styles/variables";
@import "./Styles/mediaquery";

@import "./Styles/footer";

html {
  scroll-behavior: smooth;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
input,
a,
textarea,
button,
li {
  font-family: "Inter";
}

body {
  width: 100% !important;
  position: relative;
  height: auto;
  background-color: $bg1 !important;
  margin: 0;
  height: 100%;
  overflow-y: auto;
}
p {
  font-family: "Inter";
}

.cpagination {
  display: flex;
  gap: 10px;
  width: fit-content;
  margin: 10px auto 10px auto;

  p {
    display: block;
    font-size: medium !important;
    min-width: 24px;

    width: fit-content !important;
    margin: 0 10px 0 10px;
    font-weight: 400;
    background-color: $secondary;
    text-align: center;
    height: 24px !important;
    line-height: 24px;
    color: white !important;
    border-radius: 5px;
    font-size: small;
    cursor: pointer;
  }

  h5 {
    display: block;
    font-size: medium !important;
    min-width: 24px;
    padding: 0 4px;
    width: fit-content !important;
    margin: auto;
    font-weight: 400;
    background-color: $primary;
    text-align: center;
    line-height: 24px !important;
    color: white !important;
    border-radius: 5px;
    font-size: small;
    cursor: pointer;
  }

  h5:hover {
    background-color: $accent;
  }

  .active {
    background-color: $accent;
  }

  .fa-arrow-right,
  .fa-arrow-left {
    background-color: $secondary;
    cursor: pointer !important;
    font-size: small;
    border-radius: 6px;
    color: white;
    width: 24px;
    text-align: center;
    height: 24px !important;
    line-height: 24px !important;
  }

  .fa-arrow-right:hover,
  .fa-arrow-left:hover {
    background-color: $accent;
  }
}

.navheader {
  padding: 10px;
  display: grid;
  grid-template-columns: auto 1fr;
  background-color: $primary;
  width: 100%;
  color: white;

  .fa-bars {
    color: white;
    cursor: pointer;
    display: none;
  }

  .fa {
    cursor: pointer;
  }
  .fa:hover {
    color: $accent;
  }

  .right {
    margin-left: auto;
    width: fit-content;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 8px;
  }

  @include maxwidth(mobile) {
    .fa-bars {
      display: block;
    }
  }
  @include maxwidth(tablet) {
    .fa-bars {
      display: block;
    }
  }
}

.navnavigation {
  height: 100%;
  overflow-y: auto;
  background-color: white;
  min-width: 200px;
  width: 100% !important;
  box-shadow: 1px 1px 2px #60606010;

  hr {
    margin: 10px;
    color: #60606030;
  }

  .logo {
    color: $primary;
    text-align: center;
    padding: 2em 10px 2em 10px;
    width: 100% !important;

    h3 {
      margin: 10px 0 10px 0;
      font-size: medium;
    }

    img {
      max-width: 100px;
      width: 100%;
      object-fit: contain;
      display: block;
      margin: 0 auto 10px auto;
    }
  }

  .item {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 10px;
    padding: 1em !important;
    cursor: pointer;
    height: fit-content !important;

    * {
      font-size: medium;
      color: gray;
      display: grid;
      margin: auto 0 auto 0;
    }

    .fa {
      font-size: x-large;
      display: grid;
      margin: auto;
    }
  }

  a {
    cursor: pointer;
    padding: 4px 4em 4px 4em;
    display: block;
    font-size: small;
    text-decoration: none;
    color: gray;
  }

  @include maxwidth(mobile) {
    a {
      padding: 4px 1em 4px 1em !important;
    }
    .logo {
      img {
        max-width: 32px !important;
      }
    }
  }
  @include maxwidth(tablet) {
    a {
      padding: 4px 1em 4px 1em !important;
    }
    .logo {
      img {
        img {
          max-width: 32px !important;
        }
      }
    }
  }
}

.div2equal {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  @include maxwidth(mobile) {
    display: block !important;
    gap: 2em !important;
  }
  @include maxwidth(tablet) {
    display: block !important;
    gap: 2em !important;
  }
}

.input-container {
  position: relative;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 100%;

  .label {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: $primary;
    transition: top 0.3s, font-size 0.3s, color 0.3s;

    &.up {
      top: 0;
      font-size: small;
      color: $primary;
      padding: 0 8px;
      border-radius: 2px;
      background-color: white;
    }
  }

  input {
    width: 100%;
    padding: 10px;
    border: 1px solid $primary;
    background-color: transparent;
    color: black;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
  }

  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: $primary;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
  }

  &.focused {
    textarea,
    input {
      border-color: $secondary;
    }
  }
}

.select {
  position: relative;
  margin-bottom: 1em;
  width: 100%;

  .label {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: $primary;
    background-color: transparent;
    transition: top 0.3s, font-size 0.3s, color 0.3s;

    &.up {
      top: 0;
      font-size: small;
      color: $primary;
      padding: 0 8px;
      background-color: white;
      border-radius: 2px;
    }
  }

  select {
    width: 100%;
    padding: 10px;
    border: 1px solid $primary;
    background-color: transparent;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
  }

  &.focused {
    select {
      border-color: $secondary;
    }
  }
}

.div3equal {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  @include maxwidth(mobile) {
    display: block !important;
    gap: 2em !important;
  }
  @include maxwidth(tablet) {
    display: block !important;
    gap: 2em !important;
  }
}

.pagination {
  margin-top: 1em;

  .pg_container {
    width: fit-content;
    margin: 0 0 0 auto;
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 10px;

    .fa-arrow-right,
    .fa-arrow-left {
      font-size: small;
      background-color: $accent;
      color: white;
      cursor: pointer;
      height: fit-content;
      margin: auto;
      display: block;
      padding: 4px;
      border-radius: 4px;
      box-shadow: 1px 1px 5px #60606030;
    }

    .fa:hover {
      background-color: $primary;
    }

    p {
      font-size: medium;
      height: fit-content;
      display: block;
      margin: auto;
    }
  }
}

hr {
  color: #60606070 !important;
}

.notfound {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;

  div {
    margin: auto;
    padding: 2em;

    img {
      max-width: 85%;
      width: fit-content;
      object-fit: contain;
      display: block;
      margin: auto;
    }

    p {
      color: $primary;
      font-size: xx-large;
      padding: 1.5em 0 2em;
    }

    button {
      background-color: orange;
      color: white;
      border: 1px solid orange;
      border-radius: 5px;
      padding: 4px 2em 4px 2em;
      cursor: pointer !important;
      display: block;
      margin: auto;
      height: fit-content;
    }
    button:hover {
      border: 1px solid orange;
      color: orange !important;
      background-color: transparent !important;
    }

    a {
      text-decoration: none;
      display: block;
      cursor: pointer;
      color: $primary;
      display: margin;
      margin: 1em auto 10px auto;
      text-align: center;
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $dark;
  border-radius: 10px;
}

.error {
  height: 30vh !important;
  display: flex !important;
  justify-items: center !important;

  p {
    width: 80% !important;
    display: block !important;
    color: gray !important;
    text-align: center !important;
    height: fit-content !important;
    margin: auto !important;
    font-size: large !important;
  }
}

.ripple {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba($color: #000000, $alpha: 0.2);
  display: flex;
  align-items: center;

  .container {
    width: fit-content;
    margin: auto;
  }

  .lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
}

//loading animation
.loading {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba($color: #000000, $alpha: 0);
  z-index: 99999999;

  .wrap {
    width: fit-content;
    height: fit-content;
    margin: auto;

    .lds-roller {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }

    .lds-roller div {
      animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 40px 40px;
    }

    .lds-roller div:after {
      content: " ";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #29b6f6;
      margin: -4px 0 0 -4px;
    }

    .lds-roller div:nth-child(1) {
      animation-delay: -0.036s;
    }

    .lds-roller div:nth-child(1):after {
      top: 63px;
      left: 63px;
    }

    .lds-roller div:nth-child(2) {
      animation-delay: -0.072s;
    }

    .lds-roller div:nth-child(2):after {
      top: 68px;
      left: 56px;
    }

    .lds-roller div:nth-child(3) {
      animation-delay: -0.108s;
    }

    .lds-roller div:nth-child(3):after {
      top: 71px;
      left: 48px;
    }

    .lds-roller div:nth-child(4) {
      animation-delay: -0.144s;
    }

    .lds-roller div:nth-child(4):after {
      top: 72px;
      left: 40px;
    }

    .lds-roller div:nth-child(5) {
      animation-delay: -0.18s;
    }

    .lds-roller div:nth-child(5):after {
      top: 71px;
      left: 32px;
    }

    .lds-roller div:nth-child(6) {
      animation-delay: -0.216s;
    }

    .lds-roller div:nth-child(6):after {
      top: 68px;
      left: 24px;
    }

    .lds-roller div:nth-child(7) {
      animation-delay: -0.252s;
    }

    .lds-roller div:nth-child(7):after {
      top: 63px;
      left: 17px;
    }

    .lds-roller div:nth-child(8) {
      animation-delay: -0.288s;
    }

    .lds-roller div:nth-child(8):after {
      top: 56px;
      left: 12px;
    }

    @keyframes lds-roller {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}

//map tooltip
.label {
  background: rgba(255, 255, 255, 0);
  border: 0;
  border-radius: 0px;
  box-shadow: 0 0px 0px;
}

//remove arrows input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.my_error {
  padding: 1em;
  display: flex;
  align-items: center;
  min-height: 30vh;

  p {
    text-align: center;
    width: 100%;
  }
}

.wave_loading {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  background-color: rgba($color: #000000, $alpha: 0.02);

  @keyframes wave {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  .wave {
    width: 5px;
    height: 100px;
    background: linear-gradient(45deg, $primary, $secondary);
    margin: 10px;
    animation: wave 1s linear infinite;
    border-radius: 20px;
  }
  .wave:nth-child(2) {
    animation-delay: 0.1s;
  }
  .wave:nth-child(3) {
    animation-delay: 0.2s;
  }
  .wave:nth-child(4) {
    animation-delay: 0.3s;
  }
  .wave:nth-child(5) {
    animation-delay: 0.4s;
  }
  .wave:nth-child(6) {
    animation-delay: 0.5s;
  }
  .wave:nth-child(7) {
    animation-delay: 0.6s;
  }
  .wave:nth-child(8) {
    animation-delay: 0.7s;
  }
  .wave:nth-child(9) {
    animation-delay: 0.8s;
  }
  .wave:nth-child(10) {
    animation-delay: 0.9s;
  }

  @keyframes wave {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
}

.report_loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;

  .lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
    margin: auto;
  }
  .lds-spinner div {
    transform-origin: 30px 30px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 23px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #fff;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

@include maxwidth(mobile) {
  p {
    font-size: medium;
  }
  h1 {
    font-size: large !important;
  }
}
