@import "./mediaquery";
@import "./variables";

.landing {
  min-height: 100vh;
  width: 100%;
  background-image: url("../assets/images/login_bg.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  grid-template-rows: auto 1fr auto;

  .div2equal {
    max-width: 85vw;
    width: 100%;
    height: 100%;
    margin: auto;
    gap: 2em !important;
  }

  .left {
    position: relative;
    margin-top: auto;
    height: 100%;
    color: white;

    .card {
      position: absolute;
      bottom: 44px;
      left: -100%;
      width: 100%;
      height: fit-content;
      opacity: 0;
      transition: left 1s ease-in-out, opacity 1s ease-out;
    }

    .requirements {
      padding: 10px;
      background-color: rgba($color: white, $alpha: 0.3);
      border-radius: 20px;

      h2 {
        padding: 0 0 1.5em 0;
        position: relative;
        text-align: left !important;
        width: fit-content;
        font-weight: 600;
      }

      h2::before {
        content: "";
        position: absolute;
        left: 0%;
        bottom: 16px;
        max-width: 150px;
        width: 100%;
        height: 14px;
        transform: skew(-45deg) translateX(-50%);
        background: orange;
      }

      .litem {
        display: grid;
        grid-template-columns: 32px 1fr;
        gap: 10px;
        margin: 10px 0 10px 0;

        img {
          max-height: 24px;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .bar {
      position: absolute;
      bottom: 0;
      left: 0;
      display: grid;
      grid-template-columns: repeat(3, auto);

      p {
        padding: 5px 1em;
        cursor: pointer;
      }
      p:hover {
        color: $primary;
      }
      .active {
        color: $primary;
      }
    }
  }

  .bottom {
    padding: 10px;
    background-color: rgba($color: white, $alpha: 0.3);
    border-radius: 10px 10px 0 0;
    margin-left: auto;
    margin-right: 7.5%;
    width: fit-content;
    color: white;

    .litem {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 10px;
      margin: 0 1em 0 1em;
      text-align: center;

      img {
        width: 32px;
        height: 32px;
        object-fit: contain;
        margin: auto;
      }

      h3 {
        padding-top: 8px;
      }
    }
  }

  .login {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;

    .container {
      background-color: rgba($color: white, $alpha: 1);
      min-width: 60%;
      max-width: 90%;
      width: fit-content;
      height: fit-content;
      border-radius: 8px;
      box-shadow: 1px 1px 5px #60606030;
      border: 1px solid #60606030;
      padding: 1em 2em;
      position: relative;
      margin: auto 0 auto auto;

      .fa-times {
        position: absolute;
        top: 1em;
        right: 1em;
        color: orange;
        cursor: pointer;
      }

      h1 {
        color: $uberP;
        font-size: x-large;
        text-align: center;
      }

      span {
        color: $accent;
        cursor: pointer;
      }

      p {
        font-size: small;
        font-weight: 400;
        text-align: center;
      }

      h6 {
        height: 16px;
        line-height: 16px;
        font-size: small;
        font-weight: 200;
        color: orange;
        text-align: center;
        padding: 10px;
        margin-bottom: 1em;
      }

      h3 {
        font-size: larger;
        color: $primary;
        text-align: center;
        padding: 10px;
      }

      button {
        display: block;
        margin: 10px auto 10px auto;
        border-radius: 5px;
        padding: 8px;
        width: 80%;
        background-color: $primary;
        color: white;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 3px solid rgba($primary, 0.5);
        border-top: 2px solid #60606070;
        cursor: pointer;
      }
      button:hover {
        background-color: rgba($color: $primary, $alpha: 0.8);
      }
    }

    @include maxwidth(mobile) {
      .container {
        width: 100%;
        max-height: 90vh;
        overflow-y: auto;
      }
    }
    @include maxwidth(tablet) {
      .container {
        width: 100%;
        max-height: 90vh;
        overflow-y: auto;
      }
    }
  }

  .popup {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 2em;
    display: grid;
    place-content: center;
    background-color: rgba(black, 0.3);
    z-index: 99999;

    .container {
      max-width: 350px;
      width: 100%;
    }
  }

  @include maxwidth(mobile) {
    display: block !important;

    .login {
      margin: 10vh auto 2em auto !important;

      .container {
        max-width: 100% !important;
        margin: auto !important;
      }
    }

    .left {
      margin: auto !important;
      height: 50vh;
    }

    .bottom {
      width: 100% !important;
    }
  }

  @include maxwidth(tablet) {
    display: block !important;
  }
}

.terms {
  height: 100vh;
  overflow-y: auto;

  .pcontent {
    max-width: 85%;
    width: 100%;
    margin: 2em auto 2em auto;

    p {
      font-weight: 400;
      line-height: 1.5;
      text-align: justify;
    }

    p,
    h1 {
      padding: 10px 0 10px 0;
    }

    h2 {
      font-size: medium;
      font-weight: 600;
      margin-top: 1em;
    }

    li {
      padding: 3px 0 3px 0;
      margin-left: 1em;
    }
  }
}

@keyframes zoom {
  0% {
    scale: 0.8;
  }
  100% {
    scale: 1;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
