$primary: #2863ac;
$primarylight: #4891D5;
$primarylighter: #02bc6b;
$secondary: #281e58;
$accent: orangered;
$bg: #f6fafd;
$bg1: #f6fafd;
$line: #e4e8eb;
$dark: #061e06;

$uberP: #46546F;
